@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loader {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  -webkit-animation: load8 1.1s infinite linear;
          animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}

.loader--hide {
  opacity: 0;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media (hover: none) {
  a:hover {
    color: inherit;
  }
}

.site-container {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 100vh;

  overflow-y: hidden;
  overflow-x: hidden;
}

main {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}
.content {
  flex: 1 0 auto;
  width: 100%;
}
footer {
  /*margin-top: 10px;*/
  flex: none;
}
svg.infoIcon {
  color: #027b9a;
  margin-bottom: 4px;
  margin-right: 4px;
}
@media screen and (max-width: 768px) {
  main {
    /*margin-top: 90px;*/
    margin-bottom: 20px;
  }
  footer {
    margin-top: 0px;
  }
}

